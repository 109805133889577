import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import enLocale from "date-fns/locale/en-US";

export default function BasicDatePicker({ id, onChange, dateValue }) {
  console.log("dateValue", dateValue);
  const [value, setValue] = useState(dateValue);

  useEffect(() => {
    onChange(id, value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
      <DatePicker label="Date" value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
    </LocalizationProvider>
  );
}
