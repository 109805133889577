import { useState, useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../Providers/AuthProvider";
import styled, { keyframes } from "styled-components";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import Button from "@mui/material/Button";
import LoginBackground from "../../images/login-background.jpg";
import { CSSTransition } from "react-transition-group";
import { slideInUp, fadeIn } from "react-animations";

const slideAnimation = keyframes`${slideInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Container = styled.div`
  background-image: url(${LoginBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SubContainer = styled.div`
  position: relative;
`;

const Form = styled.form`
  background-color: #fff;
  padding: 50px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px 0px rgb(0 0 0 / 10%);
`;

const LoginButton = styled(Button)`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 20px;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 0 20px;
  line-height: 40px;
  & > span.agence {
    font-weight: 400;
    display: block;
    text-align: right;
    color: #000;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  & > span.digital {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }

  & > span.digital::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }
  & > span.digital::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #fff;
  }
  & > span.digital:hover::after {
    transform: translate3d(0, 0, 0);
  }
  & > span.digital span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  & > span.digital:hover span {
    transform: translate3d(-200%, 0, 0);
  }
  & > span.digital:hover + span {
    color: #fff;
  }
`;

export default function LoginPage() {
  const [loginData, setLoginData] = useState({});
  const { onLogin } = useContext(AuthContext);
  const [isLoginDisplayed, setIsLoginDisplayed] = useState(false);

  useEffect(() => {
    setIsLoginDisplayed(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onLogin(loginData);
  };

  const handleChange = (dataName, { target: { value } }) => {
    setLoginData({ ...loginData, [dataName]: value });
  };

  return (
    <Container>
      <SubContainer className="login-container">
        <Title>
          <span class="digital" data-replace="Hello you!">
            <span>Digital Prod</span>
          </span>
          <span class="agence">agence</span>
        </Title>
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="username" label="Username" variant="standard" value={loginData.username} onChange={handleChange.bind(this, "username")} margin="dense" />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PasswordIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="password" label="Password" type="password" variant="standard" value={loginData.password} onChange={handleChange.bind(this, "password")} margin="dense" />
          </Box>
          <LoginButton type="submit" color="success">
            Login
          </LoginButton>
        </Form>
      </SubContainer>
    </Container>
  );
}
