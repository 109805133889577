import { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

export const ALERT_VARIANTS = { success: "success", error: "error", warning: "warning", info: "info" };

export default function TransitionAlerts({ popinAlertProperties: { isOpen = false, variant = "success", message = "" } }) {
  useEffect(() => {}, [isOpen]);

  return (
    <Collapse
      in={isOpen}
      sx={{
        position: "fixed",
        left: "50%",
        width: "calc(100% - 32px)",
        transform: "translateX(-50%)",
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
        bottom: "16px",
      }}
    >
      <Alert severity={variant} sx={{ mb: 2, marginBottom: 0 }}>
        {message}
      </Alert>
    </Collapse>
  );
}
