import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PopinForm from "../Popin/PopinForm";

import { DataGrid, GridToolbar, GridActionsCellItem, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
// import styled from "styled-components";
import FilterListIcon from "@mui/icons-material/FilterList";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import PopoverFilter from "./PopoverFilter";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function EnhancedTable({ headCells, label, fetchData, addData: addToList, deleteData: deleteFromList, updateData: updateList, setPopinAlert }) {
  const [popin, setPopin] = useState({ isOpen: false, action: null, title: null });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: "rating", sort: "asc" }]);

  // const [filters, setFilters] = useState({});

  // const setFilterList = (id, value) => {
  //   setFilters({ ...filters, [id]: value });
  // };

  const getData = async (filters = {}) => {
    setIsLoading(true);
    const fetchedData = await fetchData({ offset: rowsState.page, limit: rowsState.pageSize, filters });
    setData(fetchedData);
    setRowCount(+fetchedData[0]?.fullCount || 0);
    setIsLoading(false);
  };

  const addData = async (newData) => {
    const fetchedData = await addToList(newData);
    if (fetchedData) {
      setData([fetchedData, ...data]);
    }
  };

  const deleteData = async (dataId) => {
    const fetchedData = await deleteFromList(dataId);
    if (fetchedData) {
      const deletedDataIndex = data.indexOf(data.find(({ id }) => id === fetchedData.id));
      setData([...data.slice(0, deletedDataIndex), ...data.slice(deletedDataIndex + 1)]);
    }
  };

  const updateData = async (newData) => {
    const fetchedData = await updateList(newData);
    if (fetchedData) {
      const updatedDataIndex = data.indexOf(data.find(({ id }) => id === newData.id));
      setData([...data.slice(0, updatedDataIndex), fetchedData, ...data.slice(updatedDataIndex + 1)]);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsState]);

  useEffect(() => {
    // getData();
  }, [sortModel]);

  useEffect(() => {
    getData();
  }, []);

  const openPopinAdd = () => {
    setPopin({ isOpen: true, action: async (newData) => await addData(newData), title: `Add ${label}`, itemData: {} });
  };

  const openPopinUpdate = (row) => {
    setPopin({ isOpen: true, action: async (newData) => await updateData(newData), title: `Update ${label}`, itemData: row });
  };

  useEffect(() => {}, [data]);

  const editCellData = {
    field: "actions",
    type: "actions",
    sortable: false,
    editable: false,
    isOnTable: true,
    // renderCell: renderEditButton,
    width: 40,
    hideable: false,
    getActions: (params) => [<GridActionsCellItem icon={<EditIcon />} onClick={openPopinUpdate.bind(this, params.row)} label={`Edit ${label}`} />],
  };

  const onCellClick = (cell) => {
    if (cell.field === "edit") {
      openPopinUpdate(cell.row);
    }
  };

  const dataToDisplay = [...headCells.filter((cell) => cell.isOnTable), editCellData];

  const options = {
    // filter: true,
    // selectableRows: true,
    // filterType: "dropdown",
    // responsive: "stacked",
    // rowsPerPage: 10,
    // rowLength: 100,
    // maxColumns: 6,
    // customToolbar: () => {
    //   return <div>odddddddddddddddkw</div>;
    // },
  };

  // const displayCustomFilter = () => {
  //   return headCells.reduce((acc, field) => {
  //     if (field.sortable) {
  //       return [...acc, <PopoverFilter field={field} />];
  //     }
  //     return acc;
  //   }, []);
  // };

  const FilterButton = () => {
    return <PopoverFilter headCells={headCells} getData={getData} />;
  };

  // const SearchButton = () => {
  //   return (
  //     <Button variant="outlined" startIcon={<SearchIcon />} onClick={getData}>
  //       Search
  //     </Button>
  //   );
  // };

  const AddButton = () => {
    return (
      <Button variant="outlined" startIcon={<AddBoxIcon />} onClick={openPopinAdd}>
        Add
      </Button>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* {displayCustomFilter()} */}
        <FilterButton />
        {/* <SearchButton /> */}
        <AddButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <PopinForm headCells={headCells} isOpen={popin.isOpen} title={popin.title} action={popin.action} handleClose={() => setPopin({ ...popin, isOpen: false })} itemData={popin.itemData} deleteData={deleteData} />
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          onPageSizeChange={(newPageSize) => setRowsState({ ...rowsState, pageSize: newPageSize })}
          onPageChange={(newPage) => setRowsState({ ...rowsState, page: newPage })}
          onCellClick={onCellClick}
          autoHeight
          columns={dataToDisplay}
          rows={data}
          page={rowsState.page}
          pageSize={rowsState.pageSize}
          // components={{ Toolbar: GridToolbar, NoRowsOverlay: CustomNoRowsOverlay }}
          components={{ Toolbar: CustomToolbar }}
          options={options}
          loading={isLoading}
          rowCount={rowCount}
          paginationMode="server"
          sortingMode="server"
          rowsPerPageOptions={[25, 50, 100]}
          disableColumnFilter
          disableSelectionOnClick
          disableVirtualization
          // filterModel={{
          //   items: [{ columnField: "rating", operatorValue: ">", value: "2.5" }],
          // }}
          // onFilterModelChange={{}}
        />
      </div>
    </div>
  );
}
