import axios from "axios";

export const getUser = async () => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));
  try {
    const { data: user } = await axios.get("/users", { headers: { Authorization: "Bearer " + accessToken } });
    console.log("service", user);
    return user;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
