import axios from "axios";

export const getSales = async () => {
  try {
    const { data: sales } = await axios.get("/sales");
    return sales;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeSale = async (saleId) => {
  try {
    const { data: removedSale } = await axios.delete(`/sales/${saleId}`);
    return removedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addSale = async (sale) => {
  try {
    const { data: addedSale } = await axios.post(`/sales`, {
      data: sale,
    });
    return addedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateSale = async ({ id, ...saleData }) => {
  try {
    const { data: updatedSale } = await axios.put(`/sales/${id}`, {
      data: saleData,
    });
    return updatedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
