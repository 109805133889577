import { cloneElement, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import SearchIcon from "@mui/icons-material/Search";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function BasicPopover({ headCells, getData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    console.log("filters", filters);
  }, [filters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setFilterList = (id, value) => {
    if (value.length) {
      setFilters({ ...filters, [id]: value });
    } else {
      const newFilter = Object.entries(filters).reduce((acc, filter) => {
        const key = filter[0];
        const filterValue = filter[1];
        if (key !== id) {
          return { ...acc, [key]: filterValue };
        }
        return acc;
      }, {});
      setFilters(newFilter);
    }
  };

  const resetFilter = () => {
    setFilters({});
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (id, value) => {
    setFilterList(id, value);
  };

  const getActiveFiltersNb = () => {
    return Object.keys(filters)?.length || 0;
  };

  const displayFields = () => {
    return headCells.reduce((acc, field) => {
      if (field.type === "selectAutocomplete") {
        return [...acc, <SelectAutocomplete key={`filter-${field.field}`} id={field.field} label={field.headerName} onChange={handleChange} fetchAction={field.fetchAction} fieldValue={filters[field.field]}></SelectAutocomplete>];
      }
      return acc;
    }, []);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <StyledBadge badgeContent={getActiveFiltersNb()} color="primary">
          <FilterListIcon color="action" /> <Typography>Filters</Typography>
        </StyledBadge>
      </IconButton>
      <Popover
        id={"filters"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {displayFields()}
        <Button variant="outlined" startIcon={<SearchIcon />} onClick={getData.bind(null, filters)}>
          Search
        </Button>
        <Button variant="outlined" startIcon={<SearchIcon />} onClick={resetFilter}>
          Reset
        </Button>
      </Popover>
    </div>
  );
}
