import axios from "axios";

export const getProjects = async ({ offset, limit, filters }) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  const filterList = Object.entries(filters)
    .reduce((acc, val) => {
      const key = val[0];
      const values = val[1];
      if (values.length > 0) {
        return [...acc, `${key}=${values.map((v) => v.id).join(",")}`];
      }
      return acc;
    }, [])
    .join("&");

  const filterListUrl = filterList && "&" + filterList;

  try {
    const { data: projects } = await axios.get(`/projects?limit=${limit}&offset=${offset}${filterListUrl}`, { headers: { Authorization: "Bearer " + accessToken } });
    return projects;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeProject = async (projectId) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: removedProject } = await axios.delete(`/projects/${projectId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedProject;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addProject = async (project) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: addedProjects } = await axios.post(`/projects`, { data: project }, { headers: { Authorization: "Bearer " + accessToken } });
    return addedProjects;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateProject = async ({ id, ...projectData }) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: updatedProject } = await axios.put(
      `/projects/${id}`,
      {
        data: projectData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedProject;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
