import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";

export default function AppTopBar({ drawerWidth, onLogout }) {
  const LogoutButton = () => {
    return (
      <IconButton title="Logout" color="inherit" aria-label="logout" onClick={onLogout}>
        <LogoutIcon />
      </IconButton>
    );
  };

  return (
    <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Outil DP
        </Typography>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
}
