import React, { useState, useEffect, createContext, useContext } from "react";
import "./App.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Loop from "@mui/icons-material/Loop";
import DataTable from "./components/Table/DataTable";
import AlertBandeau, { ALERT_VARIANTS } from "./components/Alert/AlertBandeau";
import { Routes, Route, Link, useLocation, Outlet, Navigate, useSearchParams } from "react-router-dom";
import ClientsPage from "./components/Pages/Clients";
import CelebrationsPage from "./components/Pages/Celebrations";
import ProjectsPage from "./components/Pages/Projects";
import SectorsPage from "./components/Pages/Sectors";
import SalesPage from "./components/Pages/Sales";
import TypologiesPage from "./components/Pages/Typologies";
import LoginPage from "./components/Pages/Login";
import AuthProvider, { AuthContext } from "./components/Providers/AuthProvider";
import AppTopBar from "./components/UI/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";
/********************/
import DrawerLeft from "./components/UI/DrawerLeft";
import AppBar from "@mui/material/AppBar";

const drawerWidth = 200;

export default function App() {
  const theme = useTheme();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertData, setAlertData] = useState({ isOpen: false, variant: ALERT_VARIANTS.success, message: "" });

  const { isLogged, onLogout } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!isLogged) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <AppTopBar /> */}
      {isLogged && <AppTopBar drawerWidth={drawerWidth} onLogout={onLogout} />}
      {isLogged && <DrawerLeft drawerWidth={drawerWidth} />}
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, padding: 0 }}>
        {isLogged && <Toolbar />}
        <Routes>
          {/* <Route path="/" element={<ComponentBody />}></Route> */}
          {/* {isLoggedIn ? <Navigate to="/projects" /> : <LoginPage />} */}
          {/* <Route path="/auth" element={<LoginPage />}></Route>
<Route path="/" element={<LoginPage />}></Route> */}
          <Route path="/" element={isLogged ? <Navigate replace to="/projects" /> : <LoginPage />} />
          <Route
            path="/projects"
            element={
              <ProtectedRoute>
                <ProjectsPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <ClientsPage />
              </ProtectedRoute>
            }
          ></Route>

          {/* <Route path="/clients" element={<ClientsPage />}></Route> */}
          <Route path="/celebrations" element={<CelebrationsPage />}></Route>
          <Route path="/sectors" element={<SectorsPage />}></Route>
          <Route path="/sales" element={<SalesPage />}></Route>
          <Route path="/typologies" element={<TypologiesPage />}></Route>
        </Routes>
      </Box>
    </Box>
  );

  // return (
  //   <>
  //     <Routes>
  //       {/* <Route path="/" element={<ComponentBody />}></Route> */}
  //       <Route path="/" element={<Layout />}>
  //         {/* {isLoggedIn ? <Navigate to="/projects" /> : <LoginPage />} */}
  //         {/* <Route path="/auth" element={<LoginPage />}></Route>
  //         <Route path="/" element={<LoginPage />}></Route> */}
  //         <Route path="/" element={isLoggedIn ? <Navigate replace to="/projects" /> : <LoginPage />} />
  //         <Route
  //           path="/projects"
  //           element={
  //             <ProtectedRoute>
  //               <ProjectsPage />
  //             </ProtectedRoute>
  //           }
  //         ></Route>

  //         <Route path="/clients" element={<ClientsPage />}></Route>
  //         <Route path="/celebrations" element={<CelebrationsPage />}></Route>
  //         <Route path="/sectors" element={<SectorsPage />}></Route>
  //         <Route path="/sales" element={<SalesPage />}></Route>
  //         <Route path="/typologies" element={<TypologiesPage />}></Route>
  //       </Route>
  //     </Routes>
  //   </>
  // );
}
