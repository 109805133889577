import axios from "axios";

export const getSectors = async () => {
  try {
    const { data: sectors } = await axios.get("/sectors");
    return sectors;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeSector = async (sectorId) => {
  try {
    const { data: removedSector } = await axios.delete(`/sectors/${sectorId}`);
    return removedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addSector = async (sector) => {
  try {
    const { data: addedSector } = await axios.post(`/sectors`, {
      data: sector,
    });
    return addedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateSector = async ({ id, ...sectorData }) => {
  try {
    const { data: updatedSector } = await axios.put(`/sectors/${id}`, {
      data: sectorData,
    });
    return updatedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
