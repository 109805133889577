import axios from "axios";

export const getClients = async () => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: clients } = await axios.get("/clients", { headers: { Authorization: "Bearer " + accessToken } });
    return clients;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeClient = async (clientId) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: removedClient } = await axios.delete(`/clients/${clientId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addClient = async (client) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: addedClient } = await axios.post(
      `/clients`,
      {
        data: client,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateClient = async ({ id, ...clientData }) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));

  try {
    const { data: updatedClient } = await axios.put(
      `/clients/${id}`,
      {
        data: clientData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
