import { useState, useEffect, useContext, createContext } from "react";
import { Routes, Route, NavLink, useNavigate, useLocation } from "react-router-dom";
import { signIn } from "../../services/auth";
import { getUser } from "../../services/users";

export const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(true);

  const fetchUser = async () => {
    console.log("ici");
    const fetchedUser = await getUser();
    console.log("fetchedUser", fetchedUser);
    if (!fetchedUser.username) {
      // localStorage.removeItem("dpapp_key");
      // setIsLogged(false);
      // navigate("/");
      console.log("USER NON CONNECTE");
    } else {
      setIsLogged(true);
      console.log("USER CONNECTE");
    }
  };

  useEffect(() => {
    console.log("provider use eff", location);
    fetchUser();
  }, []);

  // const useAuth = () => {
  //   return useContext(AuthContext);
  // };

  const handleLogin = async (loginData) => {
    console.log("handleLogin");
    // const token = await fakeAuth();
    const data = await signIn(loginData);

    if (data.token) {
      localStorage.setItem("dpapp_key", JSON.stringify(data.token));
      setIsLogged(true);
      navigate("/projects");
    } else {
      localStorage.removeItem("dpapp_key");
    }
  };

  const handleLogout = () => {
    console.log("logout");
    localStorage.removeItem("dpapp_key");
    setIsLogged(false);
    navigate("/");
  };

  const value = {
    isLogged,
    onLogin: handleLogin,
    onLogout: handleLogout,
    // useAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
