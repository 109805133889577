import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function InputCheckbox({ id, onChange, label, isInputChecked = false }) {
  const [isChecked, setIsChecked] = useState(isInputChecked);

  const handleChange = ({ target: { checked } }) => {
    setIsChecked(checked);
  };

  useEffect(() => {
    onChange(id, isChecked);
  }, [isChecked]);

  return <FormControlLabel control={<Checkbox checked={isChecked} id={id} onChange={handleChange} />} label={label} />;
}
