import axios from "axios";

export const getTypologies = async () => {
  try {
    const { data: typologies } = await axios.get("/typologies");
    return typologies;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeTypology = async (typologyId) => {
  try {
    const { data: removedTypology } = await axios.delete(`/typologies/${typologyId}`);
    return removedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addTypology = async (typology) => {
  try {
    const { data: addedTypology } = await axios.post(`/typologies`, {
      data: typology,
    });
    return addedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateTypology = async ({ id, ...typologyData }) => {
  try {
    const { data: updatedTypology } = await axios.put(`/typologies/${id}`, {
      data: typologyData,
    });
    return updatedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
