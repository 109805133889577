import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function Sizes({ id, label, onChange, fieldValue = [], fetchAction }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const fetchedData = await fetchAction();
    setOptions(fetchedData);
    setIsLoading(false);
  };

  useEffect(() => {
    isOpen && fetchData();
  }, [isOpen]);

  const toggleInput = () => {
    setIsOpen(!isOpen);
  };

  const onChangeInput = (event, values) => {
    onChange(id, values);
  };

  return (
    <Stack spacing={2} sx={{ width: 500 }}>
      <Autocomplete
        multiple={true}
        value={fieldValue}
        onChange={onChangeInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="size-small-standard-multi"
        size="small"
        options={options}
        open={isOpen}
        onOpen={toggleInput}
        onClose={toggleInput}
        limitTags={2}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
