import { useState, useEffect, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import DatePicker from "../Input/DatePicker";
import InputCheckbox from "../Input/Checkbox";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isOpen, action, handleClose, title, headCells, itemData = {}, deleteData }) {
  const [formData, setFormData] = useState({});
  console.log(itemData);
  useEffect(() => {
    if (isOpen) {
      setFormData(itemData);
    } else {
      setFormData({});
    }
  }, [isOpen]);

  const onChangeSelectInput = (inputId, values) => {
    setFormData({ ...formData, [inputId]: values });
  };

  const onChangeInput = (inputId, { target: { value } }) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeInputDate = (inputId, value) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeCheckbox = (inputId, isChecked) => {
    setFormData({ ...formData, [inputId]: isChecked });
  };

  const actionPopin = () => {
    action(formData);
    handleClose();
  };

  const deleteItem = () => {
    deleteData(itemData.id);
    handleClose();
  };

  const displayFields = () => {
    return headCells.map((field) => {
      if (field.type === "textField") {
        return <TextField key={field.field} required={field.isRequired} value={formData[field.field] || ""} id={field.field} label={field.headerName} variant="standard" onChange={onChangeInput.bind(this, field.field)} />;
      } else if (field.type === "selectAutocomplete") {
        return <SelectAutocomplete key={field.field} required={field.isRequired} id={field.field} label={field.headerName} onChange={onChangeSelectInput} fetchAction={field.fetchAction} fieldValue={formData[field.field]} />;
      } else if (field.type === "dateTime") {
        return <DatePicker key={field.field} id={field.field} onChange={onChangeInputDate} dateValue={formData[field.field]} />;
      } else if (field.type === "checkbox") {
        return <InputCheckbox key={field.field} id={field.field} onChange={onChangeCheckbox} label="Sharable" />;
      }
    });
  };

  const displayDeleteButton = () => {
    if (Object.values(itemData).length > 0) {
      return (
        <Button variant="contained" color="error" onClick={deleteItem}>
          Delete
        </Button>
      );
    }
  };

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          {displayFields()}
        </Box>
      </DialogContent>
      <DialogActions>
        {displayDeleteButton()}
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={actionPopin}>Validate</Button>
      </DialogActions>
    </Dialog>
  );
}
