import { useState, useEffect } from "react";
import { getSectors, removeSector, addSector, updateSector } from "../../services/sectors";
import Loop from "@mui/icons-material/Loop";
import DataTable from "../Table/DataTable";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.name,
  },
];

const tableName = "sectors";

export default function SectorsPages() {
  const fetchData = async ({ offset, limit, filters }) => {
    const fetchedData = await getSectors({ offset, limit, filters });
    if (fetchedData.error) {
      if (fetchedData.error.code === 401) {
        // return navigate("/?auth=false", { replace: true });
      }
      return [];
    } else {
      return fetchedData;
    }
  };

  const addData = async (dataToAdd) => {
    try {
      const fetchedData = await addSector(dataToAdd);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          // return navigate("/?auth=false", { replace: true });
        }
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.error("custom error", e);
    }
  };

  const updateData = async (dataToUpdate) => {
    try {
      const fetchedData = await updateSector(dataToUpdate);
      if (fetchedData.error) {
        // throw new Error(fetchedData.error.message + " " + fetchedData.error.code);
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.log("custom error", e);
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await removeSector(dataId);
      if (fetchedData.error) {
        // throw new Error(fetchedData.error.message + " " + fetchedData.error.code);
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.log("custom error", e);
    }
  };

  return <DataTable label={tableName} fetchData={fetchData} addData={addData} deleteData={deleteData} updateData={updateData} headCells={headCells} />;
}
