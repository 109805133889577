import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from "@mui/icons-material/Business";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import PeopleIcon from "@mui/icons-material/People";
import HailIcon from "@mui/icons-material/Hail";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CelebrationIcon from "@mui/icons-material/Celebration";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const views = [
  { name: "projects", menuName: "Projects", pathName: "/projects", menuIcon: () => <AccountTreeIcon /> },

  {
    name: "providers",
    menuName: "Providers",
    pathName: "/providers",
    menuIcon: () => <HailIcon />,
  },
  {
    name: "clients",
    menuName: "Clients",
    pathName: "/clients",
    menuIcon: () => <PeopleIcon />,
  },
  {
    name: "sales",
    menuName: "Sales",
    menuIcon: () => <LoyaltyIcon />,
  },
  {
    name: "sectors",
    menuName: "Sectors",
    pathName: "/sectors",
    menuIcon: () => <BusinessIcon />,
  },
  { name: "typologies", menuName: "Typologies", pathName: "/typologies", menuIcon: () => <DeveloperBoardIcon /> },
  { name: "celebrations", menuName: "Celebrations", pathName: "/celebrations", menuIcon: () => <CelebrationIcon /> },
];

const PageLink = styled(Link)`
  color: #000;
  text-decoration: none;
`;

export default function DrawerLeft({ drawerWidth }) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Divider />
      <List>
        {views.map((v) => {
          return (
            <PageLink to={`/${v.name}`} key={v.name}>
              <ListItem button>
                <ListItemIcon>{v.menuIcon()}</ListItemIcon>
                <ListItemText primary={v.menuName} />
              </ListItem>
            </PageLink>
          );
        })}
      </List>
    </Drawer>
  );
}
