import axios from "axios";

export const getCelebrations = async () => {
  try {
    const { data: celebrations } = await axios.get("/celebrations");
    return celebrations;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeCelebration = async (celebrationId) => {
  try {
    const { data: removedCelebration } = await axios.delete(`/celebrations/${celebrationId}`);
    return removedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addCelebration = async (celebration) => {
  try {
    const { data: addedCelebration } = await axios.post(`/celebrations`, {
      data: celebration,
    });
    return addedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateCelebration = async ({ id, ...celebrationData }) => {
  try {
    const { data: updatedCelebration } = await axios.put(`/celebrations/${id}`, {
      data: celebrationData,
    });
    return updatedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
