import { useState, useEffect, useContext } from "react";
import { getProjects, removeProject, addProject, updateProject } from "../../services/projects";
import { getClients } from "../../services/clients";
import { getCelebrations } from "../../services/celebrations";
import { getSectors } from "../../services/sectors";
import { getTypologies } from "../../services/typologies";
import { getSales } from "../../services/sales";
import { useNavigate } from "react-router-dom";

import Loop from "@mui/icons-material/Loop";
import DataTable from "../Table/DataTable";

const headCells = [
  // { field: "id", headerName: "ID", editable: false },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.name,
  },
  {
    field: "clients",
    headerName: "Client",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    flex: 1,
    filterable: true,

    valueGetter: (params) => params.row.clients.map((c) => c.name).join(", "),
    fetchAction: async () => await getClients(),
  },
  {
    field: "sectors",
    headerName: "Sectors",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    flex: 1,
    filterable: true,

    valueGetter: (params) => params.row.sectors.map((s) => s.name).join(", "),
    fetchAction: async () => await getSectors(),
  },
  {
    field: "typologies",
    headerName: "Typologies",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    flex: 1,
    filterable: true,

    valueGetter: (params) => params.row.typologies.map((t) => t.name).join(", "),
    fetchAction: async () => await getTypologies(),
  },
  {
    field: "celebrations",
    headerName: "Celebration",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    flex: 1,
    filterable: true,

    valueGetter: (params) => params.row.celebrations.map((c) => c.name).join(", "),
    fetchAction: async () => await getCelebrations(),
  },
  {
    field: "sales",
    headerName: "Sale",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    flex: 1,
    filterable: true,

    valueGetter: (params) => params.row.sales.map((s) => s.name).join(", "),
    fetchAction: async () => await getSales(),
  },
  {
    field: "previewLink",
    headerName: "Preview",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    valueGetter: (params) => params.row.previewLink,
  },
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "dateTime",
    valueGetter: (params) => params.row.date.split("T")[0],
  },
  {
    field: "canShare",
    headerName: "Sharable",
    sortable: true,
    editable: false,
    isOnTable: false,
    type: "checkbox",
    valueGetter: (params) => params.row.canShare,
  },
  // {
  //   field: "edit",
  //   headerName: "",
  //   sortable: false,
  //   editable: false,
  //   isOnTable: true,
  //   renderCell: renderEditButton,
  //   // renderEditCell: renderRatingEditInputCell,
  // },
];

const tableName = "project";

export default function ProjectsPages() {
  const fetchData = async ({ offset, limit, filters }) => {
    const fetchedData = await getProjects({ offset, limit, filters });
    if (fetchedData.error) {
      if (fetchedData.error.code === 401) {
        // return navigate("/?auth=false", { replace: true });
      }
      return [];
    } else {
      return fetchedData;
    }
  };

  const addData = async (dataToAdd) => {
    try {
      const fetchedData = await addProject(dataToAdd);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          // return navigate("/?auth=false", { replace: true });
        }
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.error("custom error", e);
    }
  };

  const updateData = async (dataToUpdate) => {
    try {
      const fetchedData = await updateProject(dataToUpdate);
      if (fetchedData.error) {
        // throw new Error(fetchedData.error.message + " " + fetchedData.error.code);
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.log("custom error", e);
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await removeProject(dataId);
      if (fetchedData.error) {
        // throw new Error(fetchedData.error.message + " " + fetchedData.error.code);
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.log("custom error", e);
    }
  };

  return <DataTable label={tableName} fetchData={fetchData} addData={addData} deleteData={deleteData} updateData={updateData} headCells={headCells} />;
}
